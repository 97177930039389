import { useTranslate, BaseKey, useMany, HttpError } from "@refinedev/core";
import React, { useState } from 'react'
import { Edit } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    Radio,
    Select,
    ButtonProps,
    Typography,
    Grid,
    Button,
} from "antd";

const { Text } = Typography;

import { IProduct, ITag } from "interfaces";
import { APP_WRITE_PRODUCT_ID, APP_WRITE_TAG_ID } from "utility/constants";
import { SupplierSelectOptions } from "components/supplierSelectOptions";
import { CategorySelectOptions } from "components/categorySelectOptions";
import { ProductTypeSelectOptions } from "components/productTypeSelectOptions";
import { TagSelectOptions } from "components/tagSelectOption";
import TextArea from "antd/es/input/TextArea";
import { FileTextOutlined } from "@ant-design/icons";
import { SearchInputSelect } from "components/searchSelect/searchInputSelect";

type EditProductProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
    onFinishEdit: (values: any) => void;
    close: () => void;
};

export const EditProduct: React.FC<EditProductProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId,
    onFinishEdit,
    close
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const [textGenerateLoading, setTextGenerateLoading] = useState(false);
    const { categorySelectProps } = CategorySelectOptions();
    const { supplierSelectProps } = SupplierSelectOptions();
    const { productTypeSelectProps } = ProductTypeSelectOptions();
    const { tagSelectProps } = TagSelectOptions();
    const initTagIds = formProps?.initialValues?.TagIds;

    const integrationApiUrl = process.env.REACT_APP_BASE_INTEGRATION_URL

    const { data: tagData, isLoading, isError } = useMany<ITag, HttpError>({
      resource: APP_WRITE_TAG_ID,
      ids: initTagIds ?? [],
    });
    const selectedTags = tagData?.data?.map((tag) => ({ label: tag.Name, value: tag.id })) ?? [];

    const handleOnFinish = (values: IProduct) => {
      values.HasTags = values.TagIds.length > 0;
      onFinishEdit({
        ...values
      });
      close();
    };

    const generateText = async () => {
      setTextGenerateLoading(true);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ productName: formProps.form?.getFieldValue("Name") })
      };
      const apiUrl = process.env.REACT_APP_BASE_INTEGRATION_URL;
      const res = await fetch(`${apiUrl}/generateText`, requestOptions)
      const data = await res.json();
      formProps.form?.setFieldValue("LongDescription", data?.result)
      setTextGenerateLoading(false);
    }

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Edit
          title={"Redigera Produkt"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_PRODUCT_ID}
          recordItemId={editId}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} onFinish={handleOnFinish} layout="vertical">
            <Form.Item
              label="Namn"
              name="Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Produkttyp"
              name="TypeId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Välj typ" {...productTypeSelectProps} />
            </Form.Item>
            <Form.Item
              label="Leverantör"
              name="SupplierId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Välj leveraantör" {...supplierSelectProps} />
            </Form.Item>
            <Form.Item
              label="Kategori"
              name="CategoryId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Välj kategori" {...categorySelectProps} />
            </Form.Item>
            <Form.Item label="Taggar" name="TagIds">
              <SearchInputSelect
                defaultOptions={tagSelectProps?.options ?? []}
                selectedOptions={selectedTags}
                url={`${integrationApiUrl}/search-tags`}
                placeholder="Välj taggar"
                mode="multiple"
                {...tagSelectProps}
              ></SearchInputSelect>
            </Form.Item>
            <Form.Item
              label="Status"
              name="Published"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>{"Publicerad"}</Radio>
                <Radio value={false}>{"Avpublicerad"}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Leverantör lång beskrivning"
              name="SupplierLongDescription"
            >
              <TextArea rows={8}></TextArea>
            </Form.Item>
            <Form.Item label="Lång beskrivning" name="LongDescription">
              <TextArea rows={8}></TextArea>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            icon={<FileTextOutlined />}
            size={"small"}
            loading={textGenerateLoading}
            onClick={async () => await generateText()}
          >
            Generera text
          </Button>
        </Edit>
      </Drawer>
    );
};
