import { useTranslate, useApiUrl, BaseKey } from "@refinedev/core";

import { Edit, useSelect } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
} from "antd";

const { Text } = Typography;

import { APP_WRITE_PRODUCT_TYPE_ID } from "utility/constants";

type EditTypeProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
};

export const EditProductType: React.FC<EditTypeProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId,
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Edit
          title={"Redigera Produkttyp"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_PRODUCT_TYPE_ID}
          recordItemId={editId}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Namn"
              name="Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Form>
        </Edit>
      </Drawer>
    );
};
