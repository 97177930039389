import { CrudFilters, HttpError, useMany, useTitle } from "@refinedev/core";
import React, { useState } from 'react'

import { EditButton, List, SaveButton, TagField, useDrawerForm, FilterDropdown, useTable, Edit, BooleanField, TextField, useSelect, DeleteButton, CreateButton } from "@refinedev/antd";
import { Button, Checkbox, Drawer, Form, Input, Space, Table } from "antd";
import { ISpecification } from "interfaces";
import { APP_WRITE_SPECIFICATION_ID } from "utility/constants";
import styled from '@emotion/styled'
import { CreateSpecification } from "./create";
import { EditSpecification } from "./edit";

const TableHeader= styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
interface ISearch {
    variantSku: string;
}

export const SpecificationList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<ISpecification, HttpError, ISearch>(
        {
            pagination: {
              pageSize: 10
            },
            onSearch: (values) => {
                const filters: CrudFilters = [];
                
                filters.push({
                  field: "VariantId",
                  operator: "contains",
                  value: values.variantSku == "" ? undefined : values.variantSku,
                });

                return filters;
            },
            syncWithLocation: false
        }
    );

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        id: tagId,
        show: showEditDrawer,
    } = useDrawerForm<ISpecification, HttpError>({
        action: "edit",
        redirect: false,
    });

    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: showCreateDrawer,
  } = useDrawerForm<ISpecification, HttpError>({
      action: "create",
      resource: APP_WRITE_SPECIFICATION_ID,
      redirect: false,
  });

    return (
      <>
        <List title={"Specifikationer"}>
          {/* Table header filters */}
          <Form {...searchFormProps} layout="inline">
            <TableHeader>
              <Space>
                <Form.Item name="variantSku">
                  <Input placeholder="Sök med artikelnummer" />
                </Form.Item>
                <Button type="primary" onClick={searchFormProps.form?.submit}>
                  Filtrera
                </Button>
              </Space>
              <CreateButton
                resource={APP_WRITE_SPECIFICATION_ID}
                onClick={() => showCreateDrawer()}
              />
            </TableHeader>
          </Form>

          <br></br>

          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="Label"
              title="Namn"
              sorter
              render={(label) => <TextField value={label.Name} />}
            />
            <Table.Column dataIndex="VariantId" title="Artikelnummer" sorter />
            <Table.Column dataIndex="Value" title="Värde" sorter />
            <Table.Column<ISpecification>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditDrawer(record.id)}
                  />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
        <EditSpecification
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={tagId}
        ></EditSpecification>
        <CreateSpecification
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        ></CreateSpecification>
      </>
    );
};