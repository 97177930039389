import { message } from 'antd';
import { useUpdateMany, useCreate, useCreateMany, useUpdate } from "@refinedev/core";
import { APP_WRITE_PRODUCT_ID, APP_WRITE_VARIANT_ID } from 'utility/constants';
import { v4 as uuidv4 } from 'uuid';

export const useMultiExtractVariants = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { mutate: createMany } = useCreateMany();
  const { mutate: updateMany } = useUpdateMany();
  const { mutate: createOne } = useCreate();
  const { mutate: updateOne } = useUpdate();
  

  const createNewProductWithVariants = async (
    variantTableProps,
    originalProduct,
    selectedRowKeys
  ) => {

    messageApi.open({
      type: "loading",
      content: "Vänta bryter ut varianter..",
      duration: 0,
    });

    const selectedVariants =
      variantTableProps?.dataSource?.filter((variant) =>
        selectedRowKeys.includes(variant.id)
      ) ?? [];

    if (selectedVariants?.length == 0) {
      message.error("Inga varianter valda");
      return;
    }

    const isOneVariant = selectedVariants?.length < 2;

    //Prepare product for being copied by removing unique keys
    const formattedProduct = Object.keys(originalProduct).reduce((acc, key) => {
        if (!key.startsWith('$') && key != 'id') {
          acc[key] = originalProduct[key];
        }
        return acc;
    }, {});
    formattedProduct.Name = originalProduct?.Name + " " + selectedVariants[0]?.Name;
    formattedProduct.MainIdentifier = isOneVariant ? selectedVariants[0]?.Sku : uuidv4();
    formattedProduct.Published = false;
    formattedProduct.IsVariantProduct = !isOneVariant;

    const newProduct = await new Promise((resolve, reject) => {
      createOne(
        {
          resource: APP_WRITE_PRODUCT_ID,
          values: {
            ...formattedProduct,
          },
          successNotification: (data, values, resource) => {
            return {
              message: `Ny produkt skapad: ${data?.data?.Name}`,
              description: "Produkt skapad",
              type: "success",
            };
          },
        },
        {
          onError: (error, variables, context) => {
            reject(null);
          },
          onSuccess: (data, variables, context) => {
            resolve(data?.data);
          },
        }
      );
    });

    if(newProduct == null){
        return;
    }

    // Wrap in promise
    await new Promise((resolve, reject) => {
        updateMany(
        {
          resource: APP_WRITE_VARIANT_ID,
          values: {
            ProductId: newProduct?.id,
          },
          ids: selectedRowKeys,
        },
        {
          onError: (error, variables, context) => {
            reject();
          },
          onSuccess: (data, variables, context) => {
            resolve();
          },
        }
      );
    });

    createNewProductWithVariantsComplete(isOneVariant);
  };

  const createMultipleProductsWithOneVariant = async (
    variantTableProps,
    originalProduct,
    selectedRowKeys
  ) => {

    messageApi.open({
      type: "loading",
      content: "Vänta bryter ut varianter..",
      duration: 0,
    });

    const selectedVariants =
      variantTableProps?.dataSource?.filter((variant) =>
        selectedRowKeys.includes(variant.id)
      ) ?? [];

    if (selectedVariants?.length == 0) {
      message.error("Inga varianter valda");
      return;
    }

    const newProducts = [];

    for(const selectedVariant of selectedVariants){
          //Prepare product for being copied by removing unique keys
          const formattedProduct = Object.keys(originalProduct).reduce((acc, key) => {
            if (!key.startsWith('$') && key != 'id') {
              acc[key] = originalProduct[key];
            }
            return acc;
          }, {});
          formattedProduct.Name = originalProduct?.Name + " " + selectedVariant?.Name;
          formattedProduct.MainIdentifier = selectedVariant?.Sku;
          formattedProduct.Published = false;
          formattedProduct.IsVariantProduct = false;

          newProducts.push(formattedProduct);
    }

    const createdProducts = await new Promise((resolve, reject) => {
      createMany(
        {
          resource: APP_WRITE_PRODUCT_ID,
          values: newProducts,
          successNotification: (data, values, resource) => {
            return {
              message: `Nya produkter skapade`,
              description: "Produkter skapade",
              type: "success",
            };
          },
        },
        {
          onError: (error, variables, context) => {
            reject(null);
          },
          onSuccess: (data, variables, context) => {
            resolve(data?.data);
          },
        }
      );
    });

    for (let i = 0; i < createdProducts.length; i++) {
      const variantId = selectedVariants[i]?.id;
      await new Promise((resolve, reject) => {
        updateOne(
          {
            resource: APP_WRITE_VARIANT_ID,
            values: {
              ProductId: createdProducts[i]?.id,
              Name: createdProducts[i]?.Name,
            },
            id: variantId,
          },
          {
            onError: (error, variables, context) => {
              reject();
            },
            onSuccess: (data, variables, context) => {
              resolve();
            },
          }
        );
      });
    }

    createNewProductWithVariantsComplete(true);
  };

  const createNewProductWithVariantsComplete = (isOneVariant) => {
    messageApi.destroy();
    messageApi.open({
        type: "success",
        content: "En ny produkt med valda varianter har skapats",
        duration: 3,
    });
    if (!isOneVariant) {
      messageApi.open({
        type: "info",
        content: "Du bör se över det nya produktnamnet",
        duration: 3,
      });
    }
  };

  return { createNewProductWithVariants, createMultipleProductsWithOneVariant, contextHolder };
};