import { useTranslate, useApiUrl, BaseKey } from "@refinedev/core";
import { v4 as uuidv4 } from 'uuid';
import { Create } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
} from "antd";

const { Text } = Typography;

import { APP_WRITE_PRODUCT_ID } from "utility/constants";
import { SupplierSelectOptions } from "components/supplierSelectOptions";
import { BrandSelectOptions } from "components/brandSelectOptions";
import TextArea from "antd/es/input/TextArea";

type ProductCategoryProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateProduct: React.FC<ProductCategoryProps> = ({
    drawerProps,
    formProps,
    saveButtonProps
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    const { supplierSelectProps } = SupplierSelectOptions();
    const { brandSelectProps } = BrandSelectOptions();

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Create
          title={"Skapa Produkt"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_PRODUCT_ID}
          goBack={false}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} initialValues={{ ExtCategory:'egen skapad', MainIdentifier: uuidv4(), SupplierLongDescription: ' ' }} layout="vertical">
            <Form.Item
              label="Namn"
              name="Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Extern kategori"
              name="ExtCategory"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Leverantör"
              name="SupplierId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Välj leveraantör"
                {...supplierSelectProps}
              />
            </Form.Item>
            <Form.Item
              label="Märke"
              name="BrandId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Välj märke"
                {...brandSelectProps}
              />
            </Form.Item>
            <Form.Item
              label="Huvudidentifierare"
              name="MainIdentifier"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Leverantör lång beskrivning"
              name="SupplierLongDescription"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea rows={10}></ TextArea >
            </Form.Item>
          </Form>
        </Create>
      </Drawer>
    );
};
