import { useSelect } from "@refinedev/antd";
import { ITag } from "interfaces";
import { APP_WRITE_TAG_ID } from "utility/constants";

export const TagSelectOptions = () => {
  const { selectProps: tagSelectProps } = useSelect<ITag>({
    resource: APP_WRITE_TAG_ID,
    optionLabel: "Name",
    optionValue: "id",
    sorters: [
      {
        field: "Name",
        order: "asc",
      },
    ],
  });
  return (
    {tagSelectProps}
  );
};