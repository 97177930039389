import React, { useEffect, useState } from 'react'
import { useGetIdentity, usePermissions } from "@refinedev/core";

import { Row, Col, Card, Avatar, Typography, Space, Progress, Statistic } from "antd";
import { get } from 'http';

const { Text } = Typography;

export const DashboardPage: React.FC = () => {

    const [statsData, setStatsData] = useState<any | undefined>();
    const apiUrl = process.env.REACT_APP_BASE_INTEGRATION_URL;
    const getStatsData = async () => {
        const res = await fetch(`${apiUrl}/stats`);
        setStatsData(await res.json());
    }

    useEffect(() => {
        getStatsData();
    }, []);

    return (
      // <Row gutter={20}>
      //     <Col span={6}>
      //         <Card
      //             title="Identity"
      //             style={{ height: "300px", borderRadius: "15px" }}
      //             headStyle={{ textAlign: "center" }}
      //         >
      //             <Space align="center" direction="horizontal">
      //                 <Avatar size="large" src={identity?.avatar} />
      //                 <Text>{identity?.name}</Text>
      //             </Space>
      //         </Card>
      //     </Col>
      //     <Col span={6}>
      //         <Card
      //             title="Permissions"
      //             style={{ height: "300px", borderRadius: "15px" }}
      //             headStyle={{ textAlign: "center" }}
      //         >
      //             <Text>{permissions.data}</Text>
      //         </Card>
      //     </Col>
      // </Row>
      <Space align="start" size="large">
        <Card bordered={false}>
          <Space direction="vertical" align="center">
            <Progress
              type="circle"
              percent={statsData?.totalPublishedPercentage}
              strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
            />
            <h1>Produkter publicerade</h1>
          </Space>
        </Card>
        <Card bordered={false} style={{width: '213px'}}>
            <Statistic title="Antal Produkter" value={statsData?.totalProducts} />
        </Card>
      </Space>
    );
};
