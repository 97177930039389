import { useTranslate } from "@refinedev/core";
import { Create } from "@refinedev/antd";
import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
} from "antd";

const { Text } = Typography;

import { APP_WRITE_SPECIFICATION_ID } from "utility/constants";
import { SpecificationLabelSelectOptions } from "components/specificationLabelSelectOption";

type CreateSpecificationProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateSpecification: React.FC<CreateSpecificationProps> = ({
    drawerProps,
    formProps,
    saveButtonProps
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const {specificationLabelSelectProps} = SpecificationLabelSelectOptions();

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Create
          title={"Skapa Specifikation"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_SPECIFICATION_ID}
          goBack={false}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Typ"
              name="Label"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Välj typ"
                {...specificationLabelSelectProps}
              />
            </Form.Item>
            <Form.Item
              label="Värde"
              name="Value"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Artikelnummer"
              name="VariantId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Form>
        </Create>
      </Drawer>
    );
};
