import { useSelect } from "@refinedev/antd";
import { IProduct } from "interfaces";
import { APP_WRITE_PRODUCT_ID } from "utility/constants";

export const ProductSelectOptions = () => {
  const { selectProps: productSelectProps } = useSelect<IProduct>({
    resource: APP_WRITE_PRODUCT_ID,
    optionLabel: "Name",
    optionValue: "id",
    pagination: {
      pageSize: 10,
    },
    sorters: [
      {
        field: "Name",
        order: "asc",
      },
    ],
  });
  return (
    {productSelectProps}
  );
};