import React, { FC, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Select, Spin } from 'antd';
import type { DefaultOptionType, SelectProps } from 'antd/es/select';

type SearchInputProps = {
  defaultOptions: DefaultOptionType[];
  selectedOptions: DefaultOptionType[];
  url: string;
};

export const SearchInputSelect : FC<SearchInputProps> = ({ defaultOptions, selectedOptions, url, ...rest}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    setOptions([...defaultOptions, ...selectedOptions]);
  }, [defaultOptions, selectedOptions]);

  const searchTags = async (value: string) => {
    setFetching(true);
    const res = await fetch(`${url}?q=${value}`);
    const data = await res.json();
    const tagSearchOptions = data?.map((tag: any) => ({ label: tag.name, value: tag.id }));
    let newOptions = [...tagSearchOptions, ...selectedOptions];

    // Remove duplicates based on id
    const uniqueOptions = newOptions.reduce((acc: any[], option: any) => {
      if (!acc.find((item) => item.value === option.value)) {
        acc.push(option);
      }
      return acc;
    }, []);

    setOptions(uniqueOptions);
    setFetching(false);
  };

  return (
    <Select
      {...rest}
      onSearch={async (v) => await searchTags(v)}
      // notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
    />
  );
}