import React, { useState } from 'react'
import { CloudOutlined, PictureOutlined } from "@ant-design/icons";
import { BooleanField, EditButton, List, TextField, useDrawerForm, useModalForm, useTable } from "@refinedev/antd";
import { HttpError } from "@refinedev/core";
import { Table, Space, Button, Image, message } from "antd";
import { IProduct, IVariant } from "interfaces";
import { APP_WRITE_VARIANT_ID } from "utility/constants";
import { EditImageVariant } from "./ImageEdit";
import { EditVariant } from "./edit";
import styled from '@emotion/styled'
import { useMultiExtractVariants } from 'components/hooks/useMultiExtractVariants';

const ImageWrapper= styled.div`
  .ant-image-img {
    object-fit: contain;
  }
`

export const ProductVariantsTable: React.FC<{ record: IProduct }> = ({ record }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { createNewProductWithVariants, createMultipleProductsWithOneVariant, contextHolder } = useMultiExtractVariants();

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const { tableProps: variantTableProps } = useTable<IVariant>({
        resource: APP_WRITE_VARIANT_ID,
        sorters: {
            initial: [
                {
                    field: "IsMainVariant",
                    order: "desc",
                },
                {
                    field: "Sku",
                    order: "asc",
                }
            ],
        },
        filters: {
            initial: [
                {
                    field: "ProductId",
                    operator: "eq",
                    value: record?.id,
                },
            ],
        },
        pagination: {
            mode: "off",
        },
        syncWithLocation: false,
    });

    const {
      drawerProps: editDrawerrProps,
      formProps: editFormmProps,
      saveButtonProps: editSaveeButtonProps,
      id: variantId,
      show: showEditVariantDrawer,
    } = useDrawerForm<IVariant, HttpError>({
      action: "edit",
      resource: APP_WRITE_VARIANT_ID,
      redirect: false,
    });

    const {
      modalProps: editModalProps,
      formProps: editFormProps,
      show: showEditImageModal,
      onFinish: onFinishEditImageModal,
      close: closeEditImageModal,
      open: isImageModalOpen,
    } = useModalForm<IVariant>({
      action: "edit",
      resource: APP_WRITE_VARIANT_ID,
      warnWhenUnsavedChanges: false,
    });

    return (
      <>
        {contextHolder}
        <List title="Produktvarianter">
          
            {selectedRowKeys?.length > 0 && (
              <Space>
                <Button
                  type="primary"
                  onClick={() => createNewProductWithVariants(variantTableProps, record, selectedRowKeys)}
                  // loading={isLoading}
                >
                  Bryt ut valda varianter till en ny produkt
                </Button>
                <Button
                  type="primary"
                  onClick={() => createMultipleProductsWithOneVariant(variantTableProps, record, selectedRowKeys)}
                  // loading={isLoading}
                >
                  Bryt ut valda varianter till enskilda produkter
                </Button>
              </Space>
            )}

          <br></br>

          <Table {...variantTableProps} rowKey="id" rowSelection={rowSelection}>
            <Table.Column
              dataIndex="Images"
              render={(value) => (
                <ImageWrapper>
                  <Image width={100} height={100} src={value[0]} />
                </ImageWrapper>
              )}
              width={105}
            />
            {/* <Table.Column key="id" dataIndex="id" title="Id" /> */}
            <Table.Column key="sku" dataIndex="Sku" title="Artikelnummer" />
            <Table.Column key="name" dataIndex="Name" title="Namn" className="min-width-200"/>
            <Table.Column
              key="margin"
              dataIndex="Margin"
              title="Marginal"
              render={(value) => <TextField value={value + " %"} />}
            />
            <Table.Column
              key="purchasePrice"
              dataIndex="PurchasePriceExVat"
              title="Inköpspris (exkl. moms)"
              render={(value) => <TextField value={value + " kr"} />}
            />
            <Table.Column
              key="recPrice"
              className="min-width-100"
              dataIndex="RecPriceIncVat"
              title="Rek pris (inkl. moms)"
              render={(value) => <TextField value={value + " kr"} />}
            />
            <Table.Column
              key="price"
              className="min-width-100"
              dataIndex="PriceIncVat"
              title="Pris (inkl. moms)"
              render={(value) => <TextField value={value + " kr"} />}
            />
            <Table.Column
              key="stock"
              dataIndex="Stock"
              title="Antal i lager"
              render={(value) => <TextField value={value + " st"} />}
            />
            <Table.Column
              key="weight"
              className="min-width-75"
              dataIndex="Weight"
              title="Vikt"
              render={(value) => <TextField value={value + " kg"} />}
            />
            <Table.Column
              align="center"
              dataIndex="IsMainVariant"
              title="Huvudvariant"
              render={(value) => <BooleanField value={value} />}
            />
            <Table.Column<IVariant>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditVariantDrawer(record.id)}
                  />
                  <Button
                    size="small"
                    icon={<CloudOutlined />}
                    href={record?.SupplierImageLibraryUrl}
                    target="_blank"
                  />
                  <Button
                    size="small"
                    icon={<PictureOutlined />}
                    onClick={() => showEditImageModal(record.id)}
                  />
                </Space>
              )}
            />
            {/* <Table.Column
                    align="right"
                    key="price"
                    dataIndex="price"
                    title={t("products.fields.price")}
                    render={(value) => {
                        return (
                            <NumberField
                                options={{
                                    currency: "USD",
                                    style: "currency",
                                    notation: "compact",
                                }}
                                value={value / 100}
                            />
                        );
                    }}
                    sorter
                />
                <Table.Column
                    key="isActive"
                    dataIndex="isActive"
                    title={t("products.fields.isActive")}
                    render={(value) => <BooleanField value={value} />}
                />
                <Table.Column
                    key="createdAt"
                    dataIndex="createdAt"
                    title={t("products.fields.createdAt")}
                    render={(value) => <DateField value={value} format="LLL" />}
                    sorter
                /> */}
          </Table>
          {/* <EditProduct
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
            /> */}
        </List>
        <EditVariant
          drawerProps={editDrawerrProps}
          formProps={editFormmProps}
          saveButtonProps={editSaveeButtonProps}
          editId={variantId}
        ></EditVariant>

        <EditImageVariant
          formProps={editFormProps}
          modalProps={editModalProps}
          onFinishEdit={onFinishEditImageModal}
          closeModal={closeEditImageModal}
          open={isImageModalOpen}
        ></EditImageVariant>
      </>
    );
};