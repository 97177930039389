import {
    Refine,
    AuthBindings,
    Authenticated,
    IResourceItem,
    Action
} from "@refinedev/core";
import {
    notificationProvider,
    ThemedLayoutV2,
    ErrorComponent,
    AuthPage,
    RefineThemes,
} from "@refinedev/antd";
import {
    ApartmentOutlined,
    DashboardOutlined, PartitionOutlined, ShoppingCartOutlined, TagsOutlined, ToolOutlined, UserOutlined,
} from "@ant-design/icons";

// import dataProvider from "@refinedev/simple-rest";
import {
    AppwriteException,
    dataProvider,
    liveProvider,
} from "@refinedev/appwrite";

import routerProvider, {
    NavigateToResource,
    CatchAllNavigate,
    UnsavedChangesNotifier,
    DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Avatar, ConfigProvider } from "antd";

import "@refinedev/antd/dist/reset.css";

import { DashboardPage } from "pages/dashboard";
import { appwriteClient, account } from "utility/appwriteClient";
import { Login } from "pages/login";
import { ProductList } from "pages/products/list";
import { Logo } from "components/logo";
import { APP_WRITE_CATEGORY_ID, APP_WRITE_ORDER_ID, APP_WRITE_PRODUCT_ID, APP_WRITE_PRODUCT_TYPE_ID, APP_WRITE_SPECIFICATION_ID, APP_WRITE_TAG_ID } from "utility/constants";
import { CategoryList } from "pages/categories/list";
import { ProductTypeList } from "pages/types/list";
import { TagList } from "pages/tags/list";
import { SpecificationList } from "pages/specifications/list";
import { Header } from "components/header";
import { OrderList } from "pages/orders/list";
import styled from '@emotion/styled'

const AppContainer= styled.div`
  .min-width-75 {
    min-width: 75px;
  }
  .min-width-100 {
    min-width: 100px;
  }
  .min-width-200 {
    min-width: 200px;
  }
`

interface PageTitleHandlerProps {
    resource?: IResourceItem;
    action?: Action;
    params?: Record<string, string | undefined>;
    pathname?: string;
    autoGeneratedTitle: string;
}

const customTitleHandler = ({ resource, action, params } : PageTitleHandlerProps) => {
    let title = "Reelay";

    if(resource && action) {
        title = `${resource?.meta?.label} | Reelay`;
    }

    return title;
};

const authProvider: AuthBindings = {
    login: async ({ email, password }) => {
        try {
            await account.createEmailSession(email, password);
            return {
                success: true,
                redirectTo: "/",
            };
        } catch (e) {
            const { type, message, code } = e as AppwriteException;
            return {
                success: false,
                error: {
                    message,
                    name: `${code} - ${type}`,
                },
            };
        }
    },
    logout: async () => {
        try {
            await account.deleteSession("current");
        } catch (error: any) {
            return {
                success: false,
                error,
            };
        }

        return {
            success: true,
            redirectTo: "/login",
        };
    },
    onError: async (error) => {
        console.error(error);
        return { error };
    },
    check: async () => {
        try {
            const session = await account.get();

            if (session) {
                return {
                    authenticated: true,
                };
            }
        } catch (error: any) {
            return {
                authenticated: false,
                error: error,
                logout: true,
                redirectTo: "/login",
            };
        }

        return {
            authenticated: false,
            error: {
                message: "Check failed",
                name: "Session not found",
            },
            logout: true,
            redirectTo: "/login",
        };
    },
    getPermissions: async () => null,
    getIdentity: async () => {
        const user = await account.get();
        
        if (user) {
            return {...user , avatar: <Avatar icon={<UserOutlined />} />};
        }
        //avatar
        return null;
    },
};

const App: React.FC = () => {
    return (
      <AppContainer>
      <BrowserRouter>
        <ConfigProvider theme={RefineThemes.Green}>
          <Refine
            dataProvider={dataProvider(appwriteClient, {
              databaseId: "649d3df7ec1f60e85918",
            })}
            liveProvider={liveProvider(appwriteClient, {
              databaseId: "649d3df7ec1f60e85918",
            })}
            authProvider={authProvider}
            routerProvider={routerProvider}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  label: "Dashboard",
                  icon: <DashboardOutlined />,
                },
              },
              {
                name: APP_WRITE_ORDER_ID,
                list: "/orders",
                meta: {
                  label: "Beställningar",
                  icon: <ShoppingCartOutlined></ShoppingCartOutlined>,
                },
              },
              {
                name: APP_WRITE_CATEGORY_ID,
                list: "/categories",
                // create: "/posts/create",
                // edit: "/posts/edit/:id",
                // show: "/posts/show/:id",
                meta: {
                  label: "Kategorier",
                  icon: <ApartmentOutlined></ApartmentOutlined>,
                },
              },
              {
                name: APP_WRITE_PRODUCT_TYPE_ID,
                list: "/types",
                // create: "/posts/create",
                // edit: "/posts/edit/:id",
                // show: "/posts/show/:id",
                meta: {
                  label: "Produkttyper",
                  icon: <PartitionOutlined></PartitionOutlined>,
                },
              },
              {
                name: APP_WRITE_TAG_ID,
                list: "/tags",
                // create: "/posts/create",
                // edit: "/posts/edit/:id",
                // show: "/posts/show/:id",
                meta: {
                  label: "Taggar",
                  icon: <TagsOutlined></TagsOutlined>,
                },
              },
              {
                name: APP_WRITE_SPECIFICATION_ID,
                list: "/specifications",
                // create: "/posts/create",
                // edit: "/posts/edit/:id",
                // show: "/posts/show/:id",
                meta: {
                  label: "Specifikationer",
                  icon: <ToolOutlined></ToolOutlined>,
                },
              },
              {
                name: APP_WRITE_PRODUCT_ID,
                list: "/products",
                // create: "/posts/create",
                // edit: "/posts/edit/:id",
                // show: "/posts/show/:id",
                meta: {
                  label: "Produkter",
                },
              },
            ]}
            notificationProvider={notificationProvider}
            options={{
              liveMode: "auto",
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2
                      Title={Logo}
                      Header={() => {
                        return <Header></Header>;
                      }}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />

                <Route path="/products">
                  <Route index element={<ProductList />} />
                  {/* <Route path="edit/:id" element={<PostEdit />} />
                                <Route path="show/:id" element={<PostShow />} /> */}
                </Route>

                <Route path="/orders">
                  <Route index element={<OrderList />} />
                  {/* <Route path="edit/:id" element={<PostEdit />} />
                                <Route path="show/:id" element={<PostShow />} /> */}
                </Route>

                <Route path="/categories">
                  <Route index element={<CategoryList />} />
                  {/* <Route path="edit/:id" element={<PostEdit />} />
                                <Route path="show/:id" element={<PostShow />} /> */}
                </Route>

                <Route path="/types">
                  <Route index element={<ProductTypeList />} />
                  {/* <Route path="edit/:id" element={<PostEdit />} />
                                <Route path="show/:id" element={<PostShow />} /> */}
                </Route>

                <Route path="/tags">
                  <Route index element={<TagList />} />
                  {/* <Route path="edit/:id" element={<PostEdit />} />
                                <Route path="show/:id" element={<PostShow />} /> */}
                </Route>

                <Route path="/specifications">
                  <Route index element={<SpecificationList />} />
                  {/* <Route path="edit/:id" element={<PostEdit />} />
                                <Route path="show/:id" element={<PostShow />} /> */}
                </Route>

                {/* <Route path="/posts">
                                <Route index element={<PostList />} />
                                <Route path="edit/:id" element={<PostEdit />} />
                                <Route path="show/:id" element={<PostShow />} />
                            </Route> */}
              </Route>

              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource resource="posts" />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route
                  path="/forgot-password"
                  element={<AuthPage type="forgotPassword" />}
                />
                <Route
                  path="/update-password"
                  element={<AuthPage type="updatePassword" />}
                />
              </Route>

              <Route
                element={
                  <Authenticated>
                    <ThemedLayoutV2>
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <UnsavedChangesNotifier />
            <DocumentTitleHandler handler={customTitleHandler} />
          </Refine>
        </ConfigProvider>
      </BrowserRouter>
      </AppContainer>
    );
};

export default App;
