export const APP_WRITE_CATEGORY_ID = "649e8198bbf13e208492";
export const APP_WRITE_PRODUCT_ID = "649d3e7360d0dbbac767";
export const APP_WRITE_VARIANT_ID = "649e7f17c1eac3c405d3";
export const APP_WRITE_BRAND_ID = "64a128949e59351d065b";
export const APP_WRITE_SUPPLIER_ID = "64a14e3f524da5565f81";
export const APP_WRITE_PRODUCT_TYPE_ID = "64ad350a330c35901fb2";
export const APP_WRITE_TAG_ID = "64ad3514e71d296d1c33";
export const APP_WRITE_SPECIFICATION_ID = "64aeba7ed710044bd705";
export const APP_WRITE_SPECIFICATION_LABEL_ID = "64aeb9f572ac923ab4f0";
export const APP_WRITE_ORDER_ID = "64ca059b76579689c79d";

