import { CrudFilters, HttpError, useMany, useTitle } from "@refinedev/core";
import React, { useState } from 'react'

import { EditButton, List, SaveButton, TagField, useDrawerForm, FilterDropdown, useTable, Edit, BooleanField, TextField, useSelect, DeleteButton, CreateButton, UrlField } from "@refinedev/antd";
import { Button, Checkbox, Drawer, Form, Input, Space, Table } from "antd";
import { IBrand, ICategory, IOrder, IProduct, ISupplier, IVariant } from "interfaces";
import styled from '@emotion/styled'
import { FileImageOutlined, FileTextOutlined, ShopOutlined } from "@ant-design/icons";

const TableHeader= styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

interface ISearch {
    orderNumber: string;
}

export const OrderList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<IOrder, HttpError, ISearch>(
        {
            pagination: {
              pageSize: 10
            },
            onSearch: (values) => {
                const filters: CrudFilters = [];

                filters.push({
                  field: "ShopifyOrderNumber",
                  operator: "contains",
                  value: values.orderNumber == "" ? undefined : values.orderNumber,
                });

                return filters;
            },
            sorters: {
              initial: [
                  {
                      field: "$createdAt",
                      order: "desc",
                  },
              ],
            },
            syncWithLocation: false
        }
    );

    return (
      <>
        <List title={"Beställningar"}>
          {/* Table header filters */}
          <Form {...searchFormProps} layout="inline">
            <TableHeader>
              <Space>
                <Form.Item name="orderNumber">
                  <Input placeholder="Sök efter order" />
                </Form.Item>
                <Button type="primary" onClick={searchFormProps.form?.submit}>
                  Filtrera
                </Button>
              </Space>
            </TableHeader>
          </Form>

          <br></br>

          <Table {...tableProps} rowKey="id">
            {/* <Table.Column dataIndex="id" title="Id" /> */}
            <Table.Column
              key="ShopifyOrderNumber"
              dataIndex="ShopifyOrderNumber"
              title="Ordernummer"
              sorter
            />
            <Table.Column<IOrder>
              key="TotalAmount"
              dataIndex="TotalAmount"
              title="Totalt (inkl.moms)"
              render={(_, record) => (
                <span>{`${record?.TotalAmount} kr`}</span>
              )}
            />
            <Table.Column<IOrder>
              key="TrackingPage"
              dataIndex="TrackingPage"
              title="Tracker"
              align="center"
              render={(_, record) => (
                <Button
                  size="small"
                  icon={<ShopOutlined />}
                  href={record?.TrackingPage}
                  target="_blank"
                />
              )}
            />
            <Table.Column<IOrder>
              key="TuraXml"
              dataIndex="TuraXml"
              title="TuraUrl"
              align="center"
              render={(_, record) => (
                <Button
                  size="small"
                  icon={<FileTextOutlined />}
                  href={record?.FocusPdf}
                  target="_blank"
                />
              )}
            />
            <Table.Column<IOrder>
              key="FocusPdf"
              dataIndex="FocusPdf"
              title="FocusUrl"
              align="center"
              render={(_, record) => (
                <Button
                  size="small"
                  icon={<FileImageOutlined />}
                  href={record?.FocusPdf}
                  target="_blank"
                />
              )}
            />
            <Table.Column
              key="ContactEmail"
              dataIndex="ContactEmail"
              title="Email till kund"
            />
            <Table.Column
              key="$createdAt"
              dataIndex="$createdAt"
              title="Skapad"
              render={(value) => {
                const date = new Date(value);
                return <span>{`${date.toLocaleString("sv-SE")}`}</span>;
              }}
              sorter
            />
            {/* <Table.Column<IOrder>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditDrawer(record.id)}
                  />
                </Space>
              )}
            /> */}
          </Table>
        </List>
        {/* <EditCategory
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={categoryId}
        ></EditCategory>
        <CreateCategory
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        ></CreateCategory> */}
      </>
    );
};