import { useSelect } from "@refinedev/antd";
import { ISpecificationLabel } from "interfaces";
import { APP_WRITE_SPECIFICATION_LABEL_ID } from "utility/constants";

export const SpecificationLabelSelectOptions = () => {
  const { selectProps: specificationLabelSelectProps } = useSelect<ISpecificationLabel>({
    resource: APP_WRITE_SPECIFICATION_LABEL_ID,
    optionLabel: "Name",
    optionValue: "id",
    sorters: [
      {
        field: "Name",
        order: "asc",
      },
    ],
  });
  return (
    {specificationLabelSelectProps}
  );
};