import { Account, Appwrite, Storage, Databases, Query } from "@refinedev/appwrite";

const APPWRITE_URL = "https://webtools.digifi.se/v1";
const APPWRITE_PROJECT = "649d395acfb8fb431b48";

const appwriteClient = new Appwrite();

appwriteClient.setEndpoint(APPWRITE_URL).setProject(APPWRITE_PROJECT);

const documentData = new Databases(appwriteClient);
const account = new Account(appwriteClient);
const storage = new Storage(appwriteClient);

export { appwriteClient, account, storage, documentData, Query };