import { BaseKey, useShow } from "@refinedev/core";
import { Edit, useSelect, useSimpleList, getValueFromEvent } from "@refinedev/antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RcFile } from "antd/lib/upload/interface";
import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
    Modal,
    ModalProps,
    Image,
    Button,
    Tooltip,
    Popconfirm,
} from "antd";
import { ICategory, IVariant } from "interfaces";
import { APP_WRITE_VARIANT_ID } from "utility/constants";
import { SupplierSelectOptions } from "components/supplierSelectOptions";
import { useEffect, useState } from "react";
import { appwriteClient, documentData, storage } from "utility/appwriteClient";
import styled from '@emotion/styled'
import { DeleteOutlined, LikeOutlined } from "@ant-design/icons";
import { hashCode } from "utility/helper";

const ImageContainer = styled.div`
  position: relative;
  margin-top: 1rem;

  .image-icon {
    scale: 1.2;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    z-index: 9999;
    cursor: pointer;
  }
`

const ImageDragAndDropContainer= styled.div`
    overflow: auto;
    height: 300px;
    padding: 1.25rem 0px;
`

const IconToolbarContainer= styled.div`
  display:flex;
  justify-content: end;
  gap: 0.25rem;
`

const { Text } = Typography;

type EditModalProductProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    onFinishEdit: (values: any) => void;
    closeModal: () => void;
    open: boolean;
};

export const EditImageVariant: React.FC<EditModalProductProps> = ({
    modalProps,
    formProps,
    onFinishEdit,
    closeModal,
    open
}) => {

    const removeImage = (index : number) => {
        let items = [...images];
        if (index !== -1) {
            items.splice(index, 1);
            setImages(items)
        }
    };

    const reorder = (list: string[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const createHashCode = (values : string[]) => {
        return hashCode(values?.join('|'))?.toString();
    }

    const [ initHashCode, setInitHashCode ] = useState<string | undefined>(undefined);
    const [ images, setImages ] = useState<string[]>([]);

    useEffect(() => {
        setInitHashCode(createHashCode(formProps?.initialValues?.Images));
        setImages(formProps?.initialValues?.Images)
    }, [formProps?.initialValues?.Images, open])

    const ondragEndHandler = (result : any) => {
        if (!result.destination) return;

        let items = reorder(
            images,
            result.source.index,
            result.destination.index
        );
    
        setImages(items);
    }

    const handleOnFinish = (values: IVariant) => {
        const currentHashCode = createHashCode(images);
        onFinishEdit({
            Images: [...images],
            ImagesUpdated: initHashCode != currentHashCode ? true : false
        });
        closeModal();
    };

    return (
      <Modal {...modalProps} title={"Mediahantering"}>

        {images && (
            <div style={{margin: '1.5rem 0'}}>
                <Text strong>Dra i bilderna för att ändra ordning:</Text>
                <ImageDragAndDropContainer>
                    <DragDropContext onDragEnd={ondragEndHandler}>
                        <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} style={{display: 'flex', gap: '1.0rem'}}>
                                <Image.PreviewGroup>
                                    {images.map((image: string, i: number) => {
                                        return (
                                        <Draggable draggableId={image + i} key={image + i} index={i}>
                                            {(provided) => (
                                            <ImageContainer
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <Space direction="vertical" size="small">
                                                    <IconToolbarContainer>
                                                        <Popconfirm
                                                            title="Ta bort bilden"
                                                            description="Är du säker på att du vill ta bort bilden?"
                                                            onConfirm={() => removeImage(i)}
                                                            okText="Ja"
                                                            cancelText="Nej"
                                                        >
                                                            <Button icon={<DeleteOutlined />}/>
                                                        </Popconfirm>
                                                    </IconToolbarContainer>
                                                    <Image src={image} width={150} height={150}></Image>
                                                </Space>
                                            </ImageContainer>
                                            )}
                                        </Draggable>
                                        );
                                    })}
                                </Image.PreviewGroup>   
                            </div>
                        )}
                        </Droppable>
                    </DragDropContext>
                </ImageDragAndDropContainer>
            </div>
        )}

        <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
            <Form.Item label="Uppladning">
                <Form.Item
                    name="Images"
                    getValueFromEvent={getValueFromEvent}
                >
                    <Upload.Dragger
                        name="file"
                        listType="picture"
                        multiple
                        customRequest={async ({
                            file,
                            onError,
                            onSuccess,
                        }) => {
                            try {
                                const rcFile = file as RcFile;

                                const { $id } = await storage.createFile(
                                    "64a806c913b8b12ddd14",
                                    rcFile.name,
                                    rcFile,
                                );

                                const url = storage.getFileView(
                                    "64a806c913b8b12ddd14",
                                    $id,
                                );

                                let items = images;
                                items.push(url.toString());

                                await documentData.updateDocument('649d3df7ec1f60e85918', '649e7f17c1eac3c405d3', formProps?.initialValues?.id, {Images: items});

                                onSuccess?.({ url }, new XMLHttpRequest());
                            } catch (error) {
                                onError?.(new Error("Upload Error"));
                            }
                        }}
                    >
                        <p className="ant-upload-text">
                            Ladda upp bilder eller filmer här!
                        </p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>
        </Form>
      </Modal>
    );
};
