import { useUpdateMany } from "@refinedev/core";
import {
    Form,
    FormProps,
    Radio,
    Select,
    Typography,
    Modal,
    ModalProps
} from "antd";
import { IProduct} from "interfaces";
import { APP_WRITE_PRODUCT_ID, APP_WRITE_VARIANT_ID } from "utility/constants";
import { CategorySelectOptions } from "components/categorySelectOptions";
import { ProductTypeSelectOptions } from "components/productTypeSelectOptions";
import { TagSelectOptions } from "components/tagSelectOption";

const { Text } = Typography;

type EditModalProductProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    onFinishEdit: (values: any) => void;
    closeModal: () => void;
    open: boolean;
    selectedRowKeys: React.Key[];
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;

};

type MultiUpdateType = {
  [key: string]: any;
}

export const EditProductMulti: React.FC<EditModalProductProps> = ({
    modalProps,
    formProps,
    onFinishEdit,
    closeModal,
    open,
    selectedRowKeys,
    setSelectedRowKeys
}) => {

    const { mutate, isLoading } = useUpdateMany<IProduct>();

    const { categorySelectProps } = CategorySelectOptions();
    const { productTypeSelectProps } = ProductTypeSelectOptions();
    const { tagSelectProps } = TagSelectOptions();

    const updateProducts = (data: MultiUpdateType) => {
        mutate({
          resource: APP_WRITE_PRODUCT_ID,
          values: {
            ...data
          },
          ids: selectedRowKeys.map(String),
        },
        {
          onError: (error, variables, context) => {
              console.log("Multi published failed", error)
          },
          onSuccess: (data, variables, context) => {
            setSelectedRowKeys([])
          },
        })
      }

    const handleOnFinish = (values: IProduct) => {
        let updateObject: MultiUpdateType = {};

        if(values?.TagIds !== undefined){
          updateObject.TagIds = values?.TagIds;
          updateObject.HasTags = values?.TagIds?.length > 0;
        }

        if(values?.Published !== undefined) {
          updateObject.Published = values?.Published;
        }

        if(values?.CategoryId !== undefined) {
          updateObject.CategoryId = values?.CategoryId;
        }

        if(values?.TypeId !== undefined) {
          updateObject.TypeId = values?.TypeId;
        }

        updateProducts(updateObject);

        closeModal();
    };

    return (
      <Modal {...modalProps} title={"Redigera Valda Produkter"}>
        <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
            <Form.Item
                label="Status"
                name="Published"
            >
            <Radio.Group>
                <Radio value={true}>{"Publicerad"}</Radio>
                <Radio value={false}>{"Avpublicerad"}</Radio>
                <Radio value={undefined}>{"Oförändrad"}</Radio>
            </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Kategori"
              name="CategoryId"
            >
              <Select
                placeholder="Välj kategori"
                allowClear
                {...categorySelectProps}
              />
            </Form.Item>
            <Form.Item
              label="Produkttyp"
              name="TypeId"
            >
              <Select
                placeholder="Välj produkttyp"
                allowClear
                {...productTypeSelectProps}
              />
            </Form.Item>
            <Form.Item
              label="Taggar"
              name="TagIds"
            >
              <Select
                placeholder="Välj taggar"
                mode="multiple"
                allowClear
                {...tagSelectProps}
              />
            </Form.Item>
        </Form>
      </Modal>
    );
};
