import { BaseKey } from "@refinedev/core";

import { Edit, useSelect } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    ButtonProps,
    Typography,
    Grid,
    InputNumber,
    Select,
    Radio,
} from "antd";

const { Text } = Typography;

import { APP_WRITE_PRODUCT_ID, APP_WRITE_VARIANT_ID } from "utility/constants";
import { ProductSelectOptions } from "components/productSelectOptions";

type EditProductProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
};

export const EditVariant: React.FC<EditProductProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId,
}) => {
    const breakpoint = Grid.useBreakpoint();
    const {productSelectProps} = ProductSelectOptions();
    
    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Edit
          title={`Redigera Variant ${formProps?.initialValues?.Sku}`}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_VARIANT_ID}
          recordItemId={editId}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Namn"
              name="Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Pris"
              name="PriceIncVat"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={1}  step="1" addonAfter="kr"/>
            </Form.Item>
            <Form.Item
              label="Produkt"
              name="ProductId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Välj produkt"
                {...productSelectProps}
              />
            </Form.Item>
            <Form.Item
              label="Huvudvariant"
              name="IsMainVariant"
              rules={[
                {
                  required: true,
                },
              ]}
            >
            <Radio.Group>
                <Radio value={true}>{"Ja"}</Radio>
                <Radio value={false}>{"Nej"}</Radio>
            </Radio.Group>
            </Form.Item>
          </Form>
        </Edit>
      </Drawer>
    );
};
