import { useTranslate, useApiUrl, BaseKey } from "@refinedev/core";

import { Create } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
} from "antd";

const { Text } = Typography;

import { APP_WRITE_CATEGORY_ID, APP_WRITE_PRODUCT_ID } from "utility/constants";

type CreateCategoryProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreateCategory: React.FC<CreateCategoryProps> = ({
    drawerProps,
    formProps,
    saveButtonProps
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();

    return (
      <Drawer
        {...drawerProps}
        width={breakpoint.sm ? "500px" : "100%"}
        zIndex={1001}
      >
        <Create
          title={"Skapa Kategori"}
          saveButtonProps={saveButtonProps}
          resource={APP_WRITE_CATEGORY_ID}
          goBack={false}
          contentProps={{
            style: {
              boxShadow: "none",
            },
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Namn"
              name="Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

          </Form>
        </Create>
      </Drawer>
    );
};
