import { CrudFilters, HttpError, useMany, useTitle } from "@refinedev/core";
import React, { useState } from 'react'

import { EditButton, List, SaveButton, TagField, useDrawerForm, FilterDropdown, useTable, Edit, BooleanField, TextField, useSelect, DeleteButton, CreateButton } from "@refinedev/antd";
import { Button, Checkbox, Drawer, Form, Input, Space, Table } from "antd";
import { IBrand, ICategory, IProduct, ISupplier, IVariant } from "interfaces";
import { stringToColor } from "utility/helper";
import Select from "antd/lib/select";
import { EditProduct } from "../products/edit";
import { APP_WRITE_BRAND_ID, APP_WRITE_CATEGORY_ID, APP_WRITE_SUPPLIER_ID, APP_WRITE_VARIANT_ID } from "utility/constants";
import { SupplierSelectOptions } from "components/supplierSelectOptions";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { EditVariant } from "pages/variants/edit";
import { EditCategory } from "./edit";
import styled from '@emotion/styled'
import { CreateCategory } from "./create";


const TableHeader= styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
interface ISearch {
    name: string;
}

export const CategoryList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<ICategory, HttpError, ISearch>(
        {
            pagination: {
              pageSize: 20
            },
            filters: {
                permanent: [
                    {
                        field: "ParentId",
                        operator: "eq",
                        value: "",
                    },
                ],
            },
            onSearch: (values) => {
                const filters: CrudFilters = [];

                filters.push({
                  field: "Name",
                  operator: "contains",
                  value: values.name == "" ? undefined : values.name,
                });

                return filters;
            },
            syncWithLocation: false
        }
    );

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        id: categoryId,
        show: showEditDrawer,
    } = useDrawerForm<IProduct, HttpError>({
        action: "edit",
        redirect: false,
    });


    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: showCreateDrawer,
  } = useDrawerForm<IProduct, HttpError>({
      action: "create",
      resource: APP_WRITE_CATEGORY_ID,
      redirect: false,
  });

    return (
      <> 
        <List title={"Kategorier"}>

          {/* Table header filters */}
          <Form {...searchFormProps} layout="inline">
            <TableHeader>
              <Space>
                <Form.Item name="name">
                  <Input placeholder="Sök kategori" />
                </Form.Item>
                <Button type="primary" onClick={searchFormProps.form?.submit}>
                  Filtrera
                </Button>
              </Space>
              <CreateButton
                resource={APP_WRITE_CATEGORY_ID}
                onClick={() => showCreateDrawer()}
              />
            </TableHeader>
          </Form>

          <br></br>

          <Table
            {...tableProps}
            rowKey="id"
          >
            {/* <Table.Column dataIndex="id" title="Id" /> */}
            <Table.Column dataIndex="Name" title="Namn" sorter/>
            <Table.Column<IProduct>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEditDrawer(record.id)}
                  />
                  <DeleteButton disabled={true} hideText size="small" recordItemId={record.id}/>
                </Space>
              )}
            />
          </Table>
        </List>
        <EditCategory
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={categoryId}
        ></EditCategory>
        <CreateCategory
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        ></CreateCategory>
      </>
    );
};