import { CrudFilters, HttpError, useDeleteMany, useMany, useTitle, useUpdateMany } from "@refinedev/core";
import React, { useState, useEffect } from 'react'

import { EditButton, List, SaveButton, TagField, useDrawerForm, FilterDropdown, useTable, Edit, BooleanField, TextField, useSelect, NumberField, useModalForm, DeleteButton, CreateButton, DateField } from "@refinedev/antd";
import { Avatar, Button, Checkbox, Col, Drawer, Form, Image, Input, Popconfirm, Progress, Row, Space, Switch, Table } from "antd";
import { IBrand, ICategory, IProduct, IProductType, ISupplier, ITag, IVariant } from "interfaces";
import { getUniqueValuesFromArrayInArray, stringToColor } from "utility/helper";
import Select from "antd/lib/select";
import { EditProduct } from "./edit";
import { APP_WRITE_BRAND_ID, APP_WRITE_CATEGORY_ID, APP_WRITE_PRODUCT_ID, APP_WRITE_PRODUCT_TYPE_ID, APP_WRITE_SUPPLIER_ID, APP_WRITE_TAG_ID, APP_WRITE_VARIANT_ID } from "utility/constants";
import { SupplierSelectOptions } from "components/supplierSelectOptions";
import { EditVariant } from "pages/variants/edit";
import { CheckCircleOutlined, CloudOutlined, PictureOutlined, SearchOutlined, WarningOutlined } from "@ant-design/icons";
import { EditImageVariant } from "pages/variants/ImageEdit";
import { EditProductMulti } from "components/product/multiEdit";
import styled from '@emotion/styled'
import { Query, documentData } from "utility/appwriteClient";
import { ProductVariantsTable } from "pages/variants/list";
import { CreateProduct } from "./create";
import { ProductTypeSelectOptions } from "components/productTypeSelectOptions";
import { BrandSelectOptions } from "components/brandSelectOptions";

const TableHeaderContainer= styled.div`
  form {
    gap: 0.5rem;
  }

  .w-100-form-item {
    width: 100%;
  }

  .ant-row {
    width: 100%;
  }
`

interface ISearch {
    name: string;
    extCategory: string;
    supplier: string;
    brand: string;
    sku: string;
    type: string;
}

export const ProductList: React.FC = () => {
    const [filter, setFilter] = useState<React.Key[]>([]);
    // const [expandAllRows, setExpandAllRows] = useState<boolean | undefined>(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { mutate: deleteManyProducts } = useDeleteMany();

    useEffect(() => {
      window.addEventListener("keyup", handleKeyUp);
      return () => {
        window.removeEventListener("keyup", handleKeyUp);
      };
    }, []);

    const { tableProps, searchFormProps } = useTable<IProduct, HttpError, ISearch>(
        {
            onSearch: async (values) => {
                const filters: CrudFilters = [];

                if(values?.sku){
                  const res = await documentData.listDocuments(
                    "649d3df7ec1f60e85918",
                    APP_WRITE_VARIANT_ID,
                    [Query.search("Sku", values?.sku)]
                  );
                  filters.push({
                    field: "$id",
                    operator: "eq",
                    value: res.documents[0]?.ProductId,
                  });  
                }else{
                  filters.push({
                    field: "$id",
                    operator: "eq",
                    value: undefined,
                  });  
                }

                filters.push({
                  field: "Name",
                  operator: "contains",
                  value: values.name == "" ? undefined : values.name,
                });
                
                filters.push({
                  field: "ExtCategory",
                  operator: "contains",
                  value: values.extCategory == "" ? undefined : values.extCategory,
                });

                filters.push({
                  field: "SupplierId",
                  operator: "eq",
                  value: values.supplier == "" ? undefined : values.supplier,
                });

                filters.push({
                  field: "BrandId",
                  operator: "eq",
                  value: values.brand == "" ? undefined : values.brand, 
                });

                filters.push({
                  field: "TypeId",
                  operator: "eq",
                  value: values.type == "" ? undefined : values.type, 
                });

                filters.push({
                  field: "CategoryId",
                  operator: "eq",
                  value: filter.includes('1') ? "saknar" : undefined,
                });

                if(filter.includes('2')){
                  filters.push({
                    field: "Published",
                    operator: "eq",
                    value: true,
                  });
                }else if(filter.includes('3')){
                  filters.push({
                    field: "Published",
                    operator: "eq",
                    value: false,
                  });
                }else{
                  filters.push({
                    field: "Published",
                    operator: "eq",
                    value: undefined,
                  });
                }

                filters.push({
                  field: "TypeId",
                  operator: "eq",
                  value: filter.includes('4') ? "saknar" : undefined,
                });

                filters.push({
                  field: "HasTags",
                  operator: "eq",
                  value: filter.includes('5') ? false : undefined,
                });

                filters.push({
                  field: "IsVariantProduct",
                  operator: "eq",
                  value: filter.includes('6') ? true : undefined,
                });

                filters.push({
                  field: "LongDescription",
                  operator: "lte",
                  value: filter.includes('7') ? '' : undefined,
                });

                return filters;
            },
            pagination: {
              pageSize: 30,
            },
            sorters: {
              initial: [
                  {
                      field: "$createdAt",
                      order: "desc",
                  },
              ],
            },
            syncWithLocation: false
        }
    );

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        id: productId,
        onFinish: onFinishEditDrawer,
        show: showEditProductDrawer,
        close: closeEditDrawer,
    } = useDrawerForm<IProduct, HttpError>({
        action: "edit",
        redirect: false,
        warnWhenUnsavedChanges: false
    });

    const {
        modalProps: editMultiDrawerProps,
        formProps: editMultiFormProps,
        show: showEditMultiModal,
        onFinish: onFinishEditMultiModal,
        close: closeEditMultiModal,
        open: isMultiEditModalOpen,
    } = useModalForm<IProduct>({
        action: "create",
        resource: APP_WRITE_PRODUCT_ID,
        warnWhenUnsavedChanges: false,
    });

    const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: showCreateDrawer,
    } = useDrawerForm<IProduct, HttpError>({
      action: "create",
      resource: APP_WRITE_PRODUCT_ID,
      redirect: false,
    });

    const brandIds = tableProps?.dataSource?.map((item) => item.BrandId) ?? [];
    const brands = useMany<IBrand>({
        resource: APP_WRITE_BRAND_ID,
        ids: brandIds,
        queryOptions: {
            enabled: brandIds.length > 0,
        },
    });

    const { brandSelectProps } = BrandSelectOptions();
    const {productTypeSelectProps} = ProductTypeSelectOptions();

    const supplierIds = tableProps?.dataSource?.map((item) => item.SupplierId) ?? [];
    const suppliers = useMany<ISupplier>({
        resource: APP_WRITE_SUPPLIER_ID,
        ids: supplierIds,
        queryOptions: {
            enabled: supplierIds.length > 0,
        },
    });
    const { supplierSelectProps } = SupplierSelectOptions();


    const productTypeIds = tableProps?.dataSource?.map((item) => item.TypeId).filter(value => value != 'saknar') ?? [];
    const productTypes = useMany<IProductType>({
        resource: APP_WRITE_PRODUCT_TYPE_ID,
        ids: productTypeIds,
        queryOptions: {
            enabled: productTypeIds.length > 0,
        },
    });

    const categoryIds = tableProps?.dataSource?.map((item) => item.CategoryId).filter(value => value && value != 'saknar') ?? [];
    const categories = useMany<ICategory>({
        resource: APP_WRITE_CATEGORY_ID,
        ids: categoryIds,
        queryOptions: {
            enabled: categoryIds.length > 0,
        },
    });

    // const allIds = tableProps?.dataSource?.flatMap(obj => obj.TagIds);
    // const uniqueIDs = new Set(allIds);
    // const uniqueIDArray = Array.from(uniqueIDs);
    // console.log(uniqueIDs)

    const tagIds = getUniqueValuesFromArrayInArray(tableProps?.dataSource ?? [], 'TagIds')
    const tags = useMany<ITag>({
      resource: APP_WRITE_TAG_ID,
      ids: tagIds,
      queryOptions: {
          enabled: tagIds.length > 0,
      },
    });

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    function handleKeyUp(event : any) {
      if (event.keyCode === 13) {
        searchFormProps.form?.submit();
      }
    }

    const onConfirmDeleteManyProducts = () => {
      deleteManyProducts({
        resource: APP_WRITE_PRODUCT_ID,
        ids: selectedRowKeys as string[],
      });
      setSelectedRowKeys([]);
    }

    return (
      <>
        <List title={"Produkter"}>
          {/* Table header filters */}
          <TableHeaderContainer>
            <Form {...searchFormProps} layout="inline">
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={6} xl={6}>
                  <Form.Item name="sku" className="w-100-form-item">
                    <Input placeholder="Sök artikelnummer" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6} xl={6}>
                  <Form.Item name="name" className="w-100-form-item">
                    <Input placeholder="Sök produktnamn" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6} xl={6}>
                  <Form.Item name="extCategory" className="w-100-form-item">
                    <Input placeholder="Sök lev kategori" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6} xl={6}>
                  <Form.Item name="supplier" className="w-100-form-item">
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Välj leverantör"
                      {...supplierSelectProps}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} lg={8} xl={8}>
                  <Select
                    mode="multiple"
                    allowClear
                    value={filter}
                    style={{ width: "100%" }}
                    placeholder="Välj filter"
                    onChange={(value) => setFilter(value)}
                    options={[
                      { label: "Saknar kategori", value: "1" },
                      { label: "Saknar produkttyp", value: "4" },
                      { label: "Saknar taggar", value: "5" },
                      { label: "Publicerade", value: "2" },
                      { label: "Ej publicerade", value: "3" },
                      { label: "Har flera varianter", value: "6" },
                      { label: "Saknar produkttext", value: "7" },
                    ]}
                  />
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item name="brand" className="w-100-form-item">
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Välj märke"
                      {...brandSelectProps}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item name="type" className="w-100-form-item">
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Välj produkttyp"
                      {...productTypeSelectProps}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "7px",
                }}
              >
                <Space>
                  <Button
                    disabled={selectedRowKeys?.length > 0}
                    type="primary"
                    onClick={searchFormProps.form?.submit}
                  >
                    Filtrera
                  </Button>
                  <Button
                    disabled={selectedRowKeys?.length > 0}
                    type="default"
                    onClick={() => setFilter([])}
                  >
                    Rensa
                  </Button>
                  {selectedRowKeys?.length > 0 && (
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => showEditMultiModal()}
                        // loading={isLoading}
                      >
                        Redigera produkter
                      </Button>
                      <Popconfirm
                        title="Ta bort produkter"
                        description="Vill du ta bort valda produkter?"
                        onConfirm={() => onConfirmDeleteManyProducts()}
                        okText="Ja"
                        cancelText="Nej"
                      >
                        <Button danger>Ta bort produkter</Button>
                      </Popconfirm>
                    </Space>
                  )}
                </Space>
                <Space>
                  Expandera alla rader
                  <Switch
                    onChange={(value) => {
                      const allKeys : React.Key[] = tableProps?.dataSource?.map((item) => item.id) ?? [];
                      setExpandedRowKeys(
                        value ? allKeys : []
                      );
                    }}
                  />
                  <CreateButton
                    disabled={true}
                    resource={APP_WRITE_PRODUCT_ID}
                    onClick={() => showCreateDrawer()}
                  >
                    Skapa Produkt
                  </CreateButton>
                </Space>
              </div>
            </Form>
          </TableHeaderContainer>

          <br></br>

          <Table
            {...tableProps}
            rowKey="id"
            expandable={{
              expandedRowRender: expandedRowRender,
              expandedRowKeys: expandedRowKeys,
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandedRowKeys([...expandedRowKeys, record.id]);
                  return;
                }
                setExpandedRowKeys(
                  expandedRowKeys.filter((item) => item != record.id)
                );
              },
            }}
            rowSelection={rowSelection}
          >
            <Table.Column
              key="Name"
              className="min-width-200"
              dataIndex="Name"
              title="Namn"
              render={(value) => {
                const item = tableProps?.dataSource?.find(
                  (item) => item?.Name === value
                );
                return (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <TextField value={value} />
                    {item && item.LongDescription.length <= 1 && (
                      <WarningOutlined
                        style={{ color: "red" }}
                      ></WarningOutlined>
                    )}
                  </div>
                );
              }}
              sorter
            />
            <Table.Column
              dataIndex="TagIds"
              title="Taggar"
              render={(productTags) => {
                if (productTags?.length == 0) {
                  return <TextField value="saknas" />;
                }
                let tagsToDisplay: ITag[] = [];
                for (const productTag of productTags) {
                  const tag = tags.data?.data.find(
                    (item) => item.id === productTag
                  ) as ITag;
                  if (tag) {
                    tagsToDisplay.push(tag);
                  }
                }
                return tagsToDisplay.map((tag: ITag, i: React.Key) => {
                  return <TagField key={i} value={tag?.Name ?? ""}></TagField>;
                });
              }}
            />
            <Table.Column
              dataIndex="TypeId"
              title="Typ"
              render={(value) => {
                if (
                  productTypes?.isLoading &&
                  productTypes?.fetchStatus != "idle"
                ) {
                  return <TextField value="laddar..." />;
                }
                let name: string =
                  productTypes.data?.data.find((item) => item.id === value)
                    ?.Name ?? "";
                return (
                  <TagField value={name} color={stringToColor(name)}></TagField>
                );
              }}
            />
            <Table.Column
              dataIndex="BrandId"
              title="Märke"
              render={(value) => {
                if (brands?.isLoading) {
                  return <TextField value="laddar..." />;
                }
                return (
                  <TextField
                    value={
                      brands.data?.data.find((item) => item.id === value)?.Name
                    }
                  />
                );
              }}
            />
            <Table.Column
              dataIndex="SupplierId"
              title="Leverantör"
              render={(value) => {
                if (suppliers?.isLoading) {
                  return <TextField value="laddar..." />;
                }
                let supplierName: string =
                  suppliers.data?.data.find((item) => item.id === value)
                    ?.Name ?? "";
                return (
                  <TagField
                    value={supplierName}
                    color={stringToColor(supplierName)}
                  ></TagField>
                );
              }}
            />
            <Table.Column
              dataIndex="CategoryId"
              title="Kategori"
              render={(value) => {
                if (
                  categories?.isLoading &&
                  categories?.fetchStatus != "idle"
                ) {
                  return <TextField value="laddar..." />;
                }
                let name: string =
                  categories.data?.data.find((item) => item.id === value)
                    ?.Name ?? "";
                return (
                  <TagField value={name} color={stringToColor(name)}></TagField>
                );
              }}
            />
            <Table.Column dataIndex="ExtCategory" title="Kategori Lev" sorter responsive={["xxl"]}/>
            <Table.Column
              align="center"
              dataIndex="Published"
              title="Publicerad"
              render={(value) => <BooleanField value={value} />}
            />
            <Table.Column
              align="center"
              dataIndex="IsVariantProduct"
              title="Variantprodukt"
              responsive={["xxl"]}
              render={(value) => <BooleanField value={value} />}
            />
            <Table.Column
              key="$createdAt"
              dataIndex="$createdAt"
              title="Skapad"
              render={(value) => {
                const date = new Date(value);
                return <span>{`${date.toLocaleString("sv-SE")}`}</span>;
              }}
              sorter
            />
            <Table.Column
              key="$updatedAt"
              dataIndex="$updatedAt"
              title="Ändrad"
              render={(value) => {
                const date = new Date(value);
                return <span>{`${date.toLocaleString("sv-SE")}`}</span>;
              }}
              sorter
            />
            <Table.Column<IProduct>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => {
                return (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={record.id}
                      onClick={() => showEditProductDrawer(record.id)}
                    />
                    <DeleteButton
                      hideText
                      size="small"
                      disabled={record.Published}
                      recordItemId={record.id}
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>

        <EditProduct
          drawerProps={editDrawerProps}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          editId={productId}
          onFinishEdit={onFinishEditDrawer}
          close={closeEditDrawer}
        ></EditProduct>

        <CreateProduct
          drawerProps={createDrawerProps}
          formProps={createFormProps}
          saveButtonProps={createSaveButtonProps}
        ></CreateProduct>

        <EditProductMulti
          modalProps={editMultiDrawerProps}
          formProps={editMultiFormProps}
          onFinishEdit={onFinishEditMultiModal}
          closeModal={closeEditMultiModal}
          open={isMultiEditModalOpen}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        ></EditProductMulti>
      </>
    );
};

const expandedRowRender = (record: IProduct) => {
    return <ProductVariantsTable record={record} />;
};