import { useSelect } from "@refinedev/antd";
import { IBrand } from "interfaces";
import { APP_WRITE_BRAND_ID } from "utility/constants";

export const BrandSelectOptions = () => {
  const { selectProps: brandSelectProps } = useSelect<IBrand>({
    hasPagination: true,
    resource: APP_WRITE_BRAND_ID,
    pagination: {pageSize:60},
    optionLabel: "Name",
    optionValue: "id",
    sorters: [
        {
            field: "Name",
            order: "asc",
        },
    ],
});
  return (
    {brandSelectProps}
  );
};